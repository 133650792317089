import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Rest API: created endpoint to get surveys by skill`}</li>
      <li parentName="ul">{`Metric Panel: Added the layout*`}</li>
      <li parentName="ul">{`Content Cloud: Added the Content Cloud Linkage.`}</li>
      <li parentName="ul">{`Bycard: Added Chatbot on Bycard Sidebar`}</li>
      <li parentName="ul">{`Bug Fix: Rule with “{“ is working correctly.`}</li>
      <li parentName="ul">{`Bug Fix: Possible to add columns to Attempts Aggregation View.`}</li>
      <li parentName="ul">{`Bug Fix: V4 Login rendering the right season video.`}</li>
      <li parentName="ul">{`Bug Fix: Rest api Placeholder Post Method.`}</li>
      <li parentName="ul">{`Bug Fix: Add mute and unmute icon to audio player.`}</li>
      <li parentName="ul">{`Bug Fix: Decommissioning Byside Brand.`}</li>
      <li parentName="ul">{`Bug Fix: Product chatbot doesn’t accept instances with “-”`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      